<template>
    <v-dialog v-model="internalOpen" max-width="800" persistent>
        <div style="background-color: #FFFFFF; padding: 0 1em 1em; position: relative;">
            <div class="py-4 d-flex align-center"
                style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                <v-btn
                    style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;"
                    class="px-4 py-4" @click.stop="cerrarModal" text rounded>
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
                <h4 class="text-h4 py-2 text-center mx-auto text-uppercase font-weight-bold">Registro Compra Exterior</h4>
            </div>
            <div class="px-1 mt-1" v-if="compra">
                <v-form ref="form" class="py-8 px-6" style="background-color: #FFFFFF;" @submit.prevent>
                    <v-row>
                        <v-col cols="12" md="6">
                            <p class="font-weight-bold mb-0 mt-4">Pais elegido:</p>
                            <p class="font-weight-medium body-2 mb-0 mt-2">
                                {{ paises.find(pais => pais.id === compra?.id_pais)?.nombre || 'Na' }}
                            </p>
                        </v-col>

                        <v-col cols="12" md="6">
                            <p class="font-weight-bold mb-0 mt-4">Nombre de la compra:</p>
                            <p class="font-weight-medium body-2 mb-0 mt-2" >
                                {{ compra.nombre_compra }}
                            </p>
                        </v-col>

                        <v-col cols="12" md="6">
                            <p class="font-weight-bold mb-0 mt-4">Fecha de Contratación:</p>
                            <p class="font-weight-medium body-2 mb-0 mt-2" >
                                {{ compra.fecha_contratacion }}
                            </p>
                        </v-col>

                        <v-col cols="12" md="6">
                            <p class="font-weight-bold mb-0 mt-4">Monto Contratado:</p>
                            <p class="font-weight-medium body-2 mb-0 mt-2" >
                                {{ '$' + compra.monto_contratado }}
                            </p>
                        </v-col>

                        <v-col cols="12" md="6">
                            <p class="font-weight-bold mb-0 mt-4">Divisa seleccionada:</p>
                            <p class="font-weight-medium body-2 mb-0 mt-2">
                                {{ divisas.find(pais => pais.id === compra?.id_divisa)?.pais || 'Na' }}
                            </p>
                        </v-col>

                        <v-col cols="12" md="6">
                            <p class="font-weight-bold mb-0 mt-4">
                                {{ 'Monto contratado en ' + divisa_pais + ':' }}
                            </p>
                            <p class="font-weight-medium body-2 mb-0 mt-2" >
                                {{ '$' + compra.monto_divisa }}
                            </p>
                        </v-col>

                        <v-col md="6" cols="12">
                            <p class="font-weight-bold mb-0 mt-4">Proveedor:</p>
                            <p class="font-weight-medium body-2 mb-0 mt-2" >
                                {{ compra.proveedor }}
                            </p>
                        </v-col>

                        <v-col cols="12" md="6">
                            <p class="font-weight-bold mb-0 mt-4">Estado:</p>
                            <p class="font-weight-medium body-2 mb-0 mt-2" >
                                {{ compra.estado }}
                            </p>
                        </v-col>

                        <v-col cols="12" md="12">
                            <p class="font-weight-bold mb-0 mt-4">Insumos Adquiridos:</p>
                            <p class="font-weight-medium body-2 mb-0 mt-2" >
                                {{ compra.insumos_adquiridos }}
                            </p>
                        </v-col>
                    </v-row>
                </v-form>
                <v-card-actions class="justify-center">
                    <v-btn @click="cerrarModal" color="primary">Cerrar</v-btn>
                </v-card-actions>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import { isNil } from 'lodash';
import { mapActions, mapState } from "vuex";

export default {
    name: 'VisualizarCompraExterior',
    emits: ['on-visibility-change', 'on-save'],
    props: {
        isOpen: { type: Boolean },
        compra: { type: Object },
    },
    data: () => ({
        internalOpen: false,
    }),
    computed: {
        ...mapState("compraExterior", [
            "paises",
            "divisas",
        ]),
        divisa_pais() {
            const filtro = this.divisas.filter(
                (divisa) => divisa.id === this.compra?.id_divisa
            );
            return filtro.length > 0 ? `(${filtro[0].divisa})` : "";
        },
    },
    methods: {
        ...mapActions("compraExterior", [
            "getPaises",
            "getDivisas",
        ]),
        cerrarModal(){
            this.internalOpen = false;
        },
    },
    watch: {
        isOpen(value) {
            if (isNil(value)) return;
            this.internalOpen = value;
        },
        internalOpen(value) {
            this.$emit('on-visibility-change', value);
        },
    },
    async created() {
        await this.getPaises();
        await this.getDivisas();
    },
}
</script>