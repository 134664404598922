<template>
  <v-row no-gutters>
    <v-col cols="12" md="12">
      <v-card>
        <v-card-title class="secondary white--text">
          <h1>Compras en el Exterior</h1>
        </v-card-title>
        <v-card-actions v-if="haveRole('compra_exterior_reg_informe_crear')">
          <v-btn @click="modal = true" color="secondary"
            >Agregar registro</v-btn
          >
        </v-card-actions>
        <v-card-text class="v-list-group--active">
          <v-autocomplete
            label="Filtrar por institución"
            :items="instituciones"
            item-text="nombre"
            clearable
            item-value="id"
            v-if="haveRole('compra_exterior_reg_informe_buscar_institucion')"
            v-model="id_institucion"
            @change="actualizarFiltro"
          />
          <listado />
          <formularioDeRegistro :modal="modal" @cerrar="cerrarAction" />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import listado from "@/views/CompraExterior/components/listado.vue";
import formularioDeRegistro from "@/views/CompraExterior/components/formularioDeRegistro.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "mainCompraExterior",
  components: { listado, formularioDeRegistro },
  data() {
    return {
      modal: false,
      id_institucion: null,
    };
  },
  methods: {
    ...mapActions("compraExterior", [
      "getListadoRegistros",
      "getInstituciones",
      "setFiltroInstitucion",
    ]),
    async cerrarAction() {
      this.modal = false;
      await this.getListadoRegistros();
    },
    async actualizarFiltro() {
      await this.setFiltroInstitucion(this.id_institucion);
      await this.getListadoRegistros();
    },
  },
  computed: {
    ...mapState("compraExterior", ["instituciones"]),
  },
  async created() {
    if (this.haveRole("compra_exterior_reg_informe_buscar_institucion"))
      await this.getInstituciones();
  },
};
</script>
