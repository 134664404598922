<template>
    <v-dialog v-model="internalOpen" max-width="1800" persistent>
        <div style="background-color: #FFFFFF; padding: 0 1em 1em; position: relative;">
            <div class="py-4 d-flex align-center"
                style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                <v-btn
                    style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;"
                    class="px-4 py-4" @click.stop="cerrarModal" text rounded>
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
                <h4 class="text-h4">Editar Compra Exterior</h4>
            </div>
            <div class="px-1 mt-1" v-if="compra">
                <v-form ref="form" class="py-8 px-6" style="background-color: #FFFFFF;" @submit.prevent>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-autocomplete v-model="form.id_pais" :items="paises" label="Seleccione el país:"
                                item-text="nombre" item-value="id" clearable outlined :error-messages="id_paisErrors"
                                @input="$v.form.id_pais.$touch()" @blur="$v.form.id_pais.$touch()" />
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field outlined clearable v-model="form.nombre_compra"
                                label="Nombre/Denominación de la Contratación" @input="$v.form.nombre_compra.$touch()"
                                @blur="$v.form.nombre_compra.$touch()" :error-messages="nombre_compraErrors" />
                        </v-col>

                        <v-col cols="12" md="6">
                            <vc-date-picker v-model="form.fecha_contratacion" mode="date" locale="es"
                                :popover="config_calendar">
                                <template v-slot="{ inputEvents }">
                                    <v-text-field
                                        class="px-2 py-1 border roundend focus:outline-none focus:border-blue-300"
                                        label="Fecha de contratación/compra *" prepend-inner-icon="mdi-calendar"
                                        readonly :value="
                                        form.fecha_contratacion
                                            ? moment(form.fecha_contratacion).format(
                                                'DD/MM/YYYY'
                                            )
                                            : ''
                                        " v-on="inputEvents" outlined :error-messages="fecha_contratacionErrors"
                                        @input="$v.form.fecha_contratacion.$touch()"
                                        @blur="$v.form.fecha_contratacion.$touch()" />
                                </template>
                            </vc-date-picker>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field v-model="form.monto_contratado" clearable outlined
                                prepend-inner-icon="mdi-currency-usd" label="Monto contratado en dólares"
                                :error-messages="monto_contratadoErrors" @input="$v.form.monto_contratado.$touch()"
                                @blur="$v.form.monto_contratado.$touch()" />
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-alert outlined type="info" elevation="2" border="left" colored-border
                                icon="mdi-information">
                                <strong>Nota:</strong> El monto contratado en dólares debe ser
                                el equivalente al monto contratado en la moneda local, en la
                                fecha de la compra o contratación
                            </v-alert>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-autocomplete v-model="form.id_divisa" :items="divisas" label="Seleccione la divisa"
                                item-text="pais" item-value="id" clearable outlined :error-messages="id_divisaError"
                                @input="$v.form.id_divisa.$touch()" @blur="$v.form.id_divisa.$touch()">
                                <template v-slot:selection="{ item }">
                                    {{ `${item.pais} - ${item.divisa} - ${item.codigo}` }}
                                </template>
                                <template v-slot:item="{ item }">
                                    {{ `${item.pais} - ${item.divisa} - ${item.codigo}` }}
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field v-model="form.monto_divisa" clearable outlined
                                prepend-inner-icon="mdi-currency-usd"
                                :label="`Monto contratado en la moneda local ${divisa_pais}`"
                                :error-messages="monto_divisaError" @input="$v.form.monto_divisa.$touch()"
                                @blur="$v.form.monto_divisa.$touch()" :disabled="!form.id_divisa" />
                        </v-col>

                        <v-col md="6" cols="12">
                            <v-text-field outlined clearable v-model="form.proveedor" label="Proveedor"
                                @input="$v.form.proveedor.$touch()" @blur="$v.form.proveedor.$touch()"
                                :error-messages="proveedorErrors" />
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field clearable outlined label="Estado/Ciudad" v-model="form.estado"
                                @input="$v.form.estado.$touch()" @blur="$v.form.estado.$touch()"
                                :error-messages="estadoErrors" />
                        </v-col>

                        <v-col cols="12" md="6">
                            <div class="d-flex align-center justify-center" style="gap: 16px;">
                                <template v-if="compra.CompraExteriorRegistroInformeAdjuntos.length > 0">
                                    <template top v-for="(adjunto) in compra.CompraExteriorRegistroInformeAdjuntos">
                                        <v-tooltip>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon large v-bind="attrs" v-on="on"
                                                    @click="descargarArchivoProceso({disk: 'compraExterior',ruta_documento: adjunto.url_archivo})">
                                                    mdi-arrow-down-bold
                                                </v-icon>
                                            </template>
                                            <span>Descargar documento</span>
                                        </v-tooltip>
                                    </template>    
                                </template>

                                <v-file-input 
                                    v-model="form.contratos" 
                                    clearable 
                                    outlined
                                    label="Adjunto" 
                                    accept="application/pdf" 
                                    hide-details 
                                />
                            </div>
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-textarea label="Insumos Adquiridos" v-model="form.insumos_adquiridos"
                                placeholder="Insumos" outlined light :error-messages="insumos_adquiridosErrors"
                                @change="$v.form.insumos_adquiridos.$touch()"
                                @blur="$v.form.insumos_adquiridos.$touch()" style="width: 100%" />
                        </v-col>
                    </v-row>
                </v-form>
                <v-card-actions>
                    <v-btn @click="cerrarModal" color="primary">Cerrar</v-btn>
                    <v-btn @click="enviar" color="secondary">Guardar</v-btn>
                </v-card-actions>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import { isNil } from 'lodash';
import { mapActions, mapState } from "vuex";
import { required, decimal } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/form-validation.js';

const initialFormState = {
    id_pais: null,
    nombre_compra: null,
    fecha_contratacion: null,
    monto_contratado: null,
    id_divisa: null,
    monto_divisa: null,
    proveedor: null,
    estado: null,
    contratos: null,
    insumos_adquiridos: null
};

export default {
    name: 'EditarCompraExterior',
    emits: ['on-visibility-change', 'on-save'],
    validations: {
        form: {
            id_pais: { required },
            nombre_compra: { required },
            fecha_contratacion: { required },
            monto_contratado: { required },
            id_divisa: { required },
            monto_divisa: { required, decimal },
            proveedor: { required },
            estado: { required },
            insumos_adquiridos: { required },
        },
    },
    props: {
        isOpen: { type: Boolean },
        compra: { type: Object },
    },
    data: () => ({
        internalOpen: false,
        form: { ...initialFormState },
        config_calendar: { visibility: "click"},
    }),
    computed: {
        ...mapState("compraExterior", [
            "formulario",
            "paises",
            "cargando",
            "divisas",
        ]),
        id_paisErrors(){
            return new Validator(this.$v.form.id_pais).detect().getResult();
        },
        nombre_compraErrors(){
            return new Validator(this.$v.form.nombre_compra).detect().getResult();
        },
        fecha_contratacionErrors(){
            return new Validator(this.$v.form.fecha_contratacion).detect().getResult();
        },
        monto_contratadoErrors(){
            return new Validator(this.$v.form.monto_contratado).detect().getResult();
        },
        id_divisaError(){
            return new Validator(this.$v.form.id_divisa).detect().getResult();
        },
        divisa_pais() {
            const filtro = this.divisas.filter(
                (divisa) => divisa.id === this.form.id_divisa
            );
            return filtro.length > 0 ? `(${filtro[0].divisa})` : "";
        },
        monto_divisaError(){
            return new Validator(this.$v.form.monto_divisa).detect().getResult();
        },
        proveedorErrors(){
            return new Validator(this.$v.form.proveedor).detect().getResult();
        },
        estadoErrors(){
            return new Validator(this.$v.form.estado).detect().getResult();
        },
        insumos_adquiridosErrors() {
            return new Validator(this.$v.form.insumos_adquiridos).detect().getResult();
        },
    },
    methods: {
        ...mapActions("compraExterior", [
            "getPaises",
            "getDivisas",
        ]),
        ...mapActions("procesoCompraDoc", ["descargarArchivoProceso"]),
        async enviar(){
            this.$v.form.$touch();
            if (this.$v.form.$invalid) return;
            console.log('this.form :>> ', this.form);
            const formData = new FormData();
            formData.append("id_pais", this.form?.id_pais);
            formData.append("nombre_compra", this.form?.nombre_compra);
            formData.append("insumos_adquiridos", this.form?.insumos_adquiridos);
            formData.append("monto_contratado", this.form?.monto_contratado);
            formData.append("proveedor", this.form?.proveedor);
            formData.append("fecha_contratacion", this.form?.fecha_contratacion);
            formData.append("contratos", this.form?.contratos);
            formData.append("id_divisa", this.form?.id_divisa);
            formData.append("monto_divisa", this.form?.monto_divisa);
            formData.append("estado", this.form?.estado);
            // this.editando = true;
            this.services.compraEnExterior
                .editarRegistro(formData, this.compra.id)
                .catch((error) => {
                    Vue?.prototype.pushAppMessage({
                        message: "Error al editar, intenté más tarde",
                        type: "warning",
                    });
                    commit("setCargando");
                })
                .then(() => {
                    this.cerrarModal();
                    this.$emit('on-save');
                })
                .finally(() => {
                    // this.editando = false;
                });
        },
        cerrarModal(){
            this.internalOpen = false;
            this.limpiarFormulario();
        },
        limpiarFormulario(){
            this.$v.form.$reset();
            //this.form = { ...initialFormState };
        }
    },
    watch: {
        isOpen(value) {
            if (isNil(value)) return;
            this.internalOpen = value;
        },
        internalOpen(value) {
            this.$emit('on-visibility-change', value);
        },
        compra: {
            handler(value) {
                this.form.id_pais = value?.id_pais;
                this.form.nombre_compra = value?.nombre_compra;
                this.form.fecha_contratacion = value?.fecha_contratacion;
                this.form.monto_contratado = value?.monto_contratado;
                this.form.id_divisa = value?.id_divisa;
                this.form.monto_divisa = value?.monto_divisa;
                this.form.proveedor = value?.proveedor;
                this.form.estado = value?.estado;
                this.form.contratos = value?.contratos;
                this.form.insumos_adquiridos = value?.insumos_adquiridos;
            },
            deep: true,
        },
    },
    async created() {
        await this.getPaises();
        await this.getDivisas();
    },
}
</script>