<template>
  <Fragment>
  <data-table-component :headers="headers" :items="listadoRegistros" :show_loading="cargando"
    :total_registros="paginacion.total_elements" v-models:select="paginacion.per_page" v-models:pagina="paginacion.page"
    @paginar="paginate">
    <template v-slot:item.acciones="{ item }">
      <v-tooltip top v-for="(adjunto, index) in item?.CompraExteriorRegistroInformeAdjuntos" :key="index">
        <template v-slot:activator="{ on, attrs }">
          <v-icon large v-bind="attrs" v-on="on"
            @click="descargarArchivoProceso({disk: 'compraExterior',ruta_documento: adjunto.url_archivo})">
            mdi-arrow-down-bold
          </v-icon>
        </template>
        <span>Descargar documento</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon large v-bind="attrs" v-on="on" @click.stop="visualizarCompraExterior(item)">
            mdi-eye
          </v-icon>
        </template>
        <span>Ver Registro</span>
      </v-tooltip>
      <v-tooltip top  v-if="haveRole('compra_exterior_reg_informe_crear')">
        <template v-slot:activator="{ on, attrs }">
          <v-icon large v-bind="attrs" v-on="on" @click.stop="editarCompraExterior(item)">
            mdi mdi-cog
          </v-icon>
        </template>
        <span>Configuración</span>
      </v-tooltip>
    </template>
  </data-table-component>
  <VisualizarCompraExterior 
  :isOpen="modalVisualizacionCompraExterior" 
  :compra="compraActiva" 
  @on-visibility-change="manejarVisibilidadModalVisualizacionCompraExterior"
  />
  <EditarCompraExterior 
  :isOpen="modalEditarCompraExterior" 
  :compra="compraActiva" 
  @on-visibility-change="manejarVisibilidadModalEditarCompraExterior"
  @on-save="getListadoRegistros"
  />
</Fragment>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import {mapActions, mapState} from "vuex";
import VisualizarCompraExterior from "./VisualizarCompraExterior.vue";
import EditarCompraExterior from "./EditarCompraExterior.vue";
import { Fragment } from "vue-fragment";

export default {
  name: 'listadoCompraExterior',
  components: {DataTableComponent, VisualizarCompraExterior, EditarCompraExterior, Fragment},
  data() {
    return {
      headers: [
        {text: 'Lugar (pais)', value: 'pais.nombre', align: 'center'},
        {text: 'Denominación de la contratación', value: 'nombre_compra', align: 'center'},
        {text: 'Insumos adquiridos', value: 'insumos_adquiridos', align: 'center'},
        {text: 'Monto contratado', value: 'monto_contratado', align: 'center'},
        {text: 'Acciones', value: 'acciones', align: 'center'},
      ],
      modalVisualizacionCompraExterior: false,
      modalEditarCompraExterior: false,
      compraActiva: null,
    }
  },
  computed: {
    ...mapState('compraExterior', ['listadoRegistros', 'cargando', 'paginacion'])
  },
  methods: {
    ...mapActions(
        'compraExterior',
        [
          'getListadoRegistros',
          'paginar',
        ]
    ),
    ...mapActions("procesoCompraDoc", ["descargarArchivoProceso"]),
    async paginate(params){
      this.paginar(params)
      await this.getListadoRegistros()
    },
    manejarVisibilidadModalVisualizacionCompraExterior(visible) {
      this.modalVisualizacionCompraExterior = visible;
    },
    manejarVisibilidadModalEditarCompraExterior(visible){
      this.modalEditarCompraExterior = visible;
    },
    visualizarCompraExterior(compra) {
      this.compraActiva =  {...compra};
      this.modalVisualizacionCompraExterior = true;
    },
    editarCompraExterior(compra){
      this.compraActiva = {...compra};
      this.modalEditarCompraExterior = true;
    }
  },
  async created() {
    await this.getListadoRegistros();
  }
}
</script>
